import { StyleProps, VStack } from "@chakra-ui/react";
import ChatContainer from "@components/UI/ChatContainer";
import ChatInput from "@components/UI/ChatInput";
import { useSelector } from "react-redux";

import store from "@/store/store";

function HomeContent(props: StyleProps) {
  const isSessionClosed = useSelector(store.select.chatModel.isSessionClosed);
  return (
    <VStack
      bg="brand.background"
      px="2"
      gap={0}
      spacing={0}
      height={"100%"}
      position="relative"
      borderBottomRadius="inherit"
      justifyContent={"initial"}
      {...props}
    >
      <ChatContainer />
      {!isSessionClosed && <ChatInput />}
    </VStack>
  );
}

export default HomeContent;
