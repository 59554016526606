import { defaultTheme } from "@configs/theme";
import {
  BotConfigData,
  BotThemeData,
  OwnerInfo,
} from "@CustomTypes/config.type";
import { createModel } from "@rematch/core";

import ConfigService from "@/services/config.service";

import { IRootModel } from ".";

type ConfigModelState = {
  storeHydrated: boolean; // true when store is hydrated
  language: string;
  theme: BotThemeData;
  clogo?: string; // Company Logo
  bimg?: string; // Bot Image,
  skip_login?: boolean;
  bot_name?: string;
  name?: string; // Title of the bot,
  p_url?: string; // Privary Policy URL
  owner?: OwnerInfo;
};

const initialState: ConfigModelState = {
  storeHydrated: false,
  language: "auto",
  theme: defaultTheme,
};

const configModel = createModel<IRootModel>()({
  state: initialState,
  reducers: {
    setHydrated(state) {
      return { ...state, storeHydrated: true };
    },
    setLanguage(state, language: string) {
      return { ...state, language };
    },
    setTheme(state, theme?: BotThemeData) {
      if (theme) {
        state.theme = theme;
      }
    },
    setCompanyLogo(state, clogo?: string) {
      if (clogo) {
        state.clogo = clogo;
      }
    },
    setBotImage(state, bimg?: string) {
      if (bimg) {
        state.bimg = bimg;
      }
    },
    setBotConfigState(state, newState: Partial<BotConfigData>) {
      return { ...state, ...newState };
    },
  },
  selectors: (slice) => ({
    isHydrated() {
      return slice((state) => state.storeHydrated);
    },
    privaryPolicyUrl() {
      return slice((state) => state.p_url);
    },
    owner() {
      return slice((state) => state.owner);
    },
    theme() {
      return slice((state) => state.theme);
    },
    companyLogo() {
      return slice((state) => state.clogo);
    },
    botImage() {
      return slice((state) => state.bimg);
    },
    title() {
      return slice((state) => state.name);
    },
    botName() {
      return slice((state) => state.bot_name);
    },
    skipLogin() {
      return slice((state) => state.skip_login);
    },
  }),
  effects: (dispatch) => ({
    async fetchTheme(projectId: string) {
      const baseUrl = import.meta.env.VITE_CONFIG_SERVICE_URL;
      const service = new ConfigService(baseUrl, projectId);
      const config = await service.getConfig();

      let clogo = config?.clogo;
      let bimg = config?.bimg;

      if (clogo && !clogo.startsWith("http")) {
        clogo = `${baseUrl}${clogo}`;
      }
      if (bimg && !bimg.startsWith("http")) {
        bimg = `${baseUrl}${bimg}`;
      }

      dispatch.configModel.setBotConfigState({
        ...config,
        bimg,
        clogo,
      });

      if (config) {
        config.bimg = bimg;
        config.clogo = clogo;
      }

      return config;
    },
  }),
});

export default configModel;
