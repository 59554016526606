import {
  Box,
  chakra,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IMessage, IMessageDocument } from "@CustomTypes/message.type";
import { useConfig } from "@hooks/config";
import useLogging from "@hooks/logging";
import Upload from "rc-upload";
import { RcFile, UploadProgressEvent } from "rc-upload/lib/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiUploadCloud } from "react-icons/fi";
import { generate } from "short-uuid";

import { store } from "@/store/store";

type Props = {
  canClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  supportedMimeType?: string[];
};

function UploadDialog({ canClose, isOpen, onClose, supportedMimeType }: Props) {
  const config = useConfig();
  const logging = useLogging();
  const [dragOver, setDragOver] = useState(false);
  const supportedFileSize = parseInt(
    import.meta.env.VITE_MAX_FILE_UPLOAD_SIZE || "5"
  ); // MB
  const toast = useToast();
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<number | null>(null);

  const handleChange = (file: RcFile) => {
    const size = file.size / 1024 / 1024;

    if (size > supportedFileSize) {
      toast({
        title: t("File size is too large"),
        description: `${t(
          "File size should be less than"
        )} ${supportedFileSize}MB`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    const mime = file.type;
    if (supportedMimeType && !supportedMimeType.includes(mime)) {
      toast({
        title: t("File type is not supported"),
        description: `${t("File type should be")} ${supportedMimeType.join(
          ", "
        )}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const getUploadUrl = () => {
    const url = `${config.endpoint}/upload_file/${config.projectId}/`;
    return url;
  };

  const getData = () => {
    return {};
  };

  const onSuccess = async (response: Record<string, unknown>, file: RcFile) => {
    const document = response as IMessageDocument;
    const text = `${file.name} (${(file.size / 1024 / 1024).toFixed(2)}MB))`;
    const message: IMessage = {
      id: generate(),
      document: document,
      text: text,
      type: "Document",
      timestamp: new Date().getTime(),
      role: "User",
      identity: config.identity,
    };
    await store.dispatch.chatModel.sendMessageAndWait(message);
    onClose();
    setUploading(null);
  };

  const onProgress = (event: UploadProgressEvent, file: RcFile) => {
    logging.debug(event, file);
    setUploading(event.percent || 0);
  };

  const onStart = (file: RcFile) => {
    setUploading(0);
    logging.debug("Upload Starting ", file);
  };

  const onError = (
    error: Error,
    ret: Record<string, unknown>,
    file: RcFile
  ) => {
    logging.error(error, ret, file);
    setUploading(null);
    toast({
      title: t("Upload failed"),
      description: error.message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      closeOnEsc={canClose}
      closeOnOverlayClick={canClose}
    >
      <ModalOverlay />
      <ModalContent m={4}>
        {canClose && <ModalCloseButton />}
        <ModalHeader>Upload</ModalHeader>
        <ModalBody>
          {isOpen && (
            <Upload
              action={getUploadUrl}
              data={getData}
              headers={{
                "x-channel-secret": config.verifyKey,
              }}
              beforeUpload={handleChange}
              onSuccess={onSuccess}
              onProgress={onProgress}
              onStart={onStart}
              onError={onError}
              multiple={false}
              type="drag"
              accept={supportedMimeType?.join(",")}
              disabled={false}
            >
              {uploading ? (
                <Progress bg={"brand.primary"} value={uploading} />
              ) : (
                <Box
                  m={0}
                  border="2px dashed"
                  borderColor={"blackAlpha.400"}
                  height="150px"
                  borderRadius={"md"}
                  p={2}
                  bg={dragOver ? "blackAlpha.100" : "transparent"}
                  onDragLeave={() => setDragOver(false)}
                  onDragOver={() => setDragOver(true)}
                  onDragEnter={() => setDragOver(true)}
                  onDrop={() => setDragOver(false)}
                >
                  <VStack>
                    <IconButton
                      onClick={undefined}
                      aria-label="upload-file"
                      icon={<FiUploadCloud />}
                    />
                    <Text fontSize={"xs"} textAlign={"center"}>
                      <chakra.span fontWeight={"bold"} color="brand.primary">
                        {t("Click to upload")}
                      </chakra.span>{" "}
                      {t("or drag and drop file here")} <br />( {t("Upto")}{" "}
                      {supportedFileSize}MB)
                    </Text>
                    {supportedMimeType && (
                      <Tooltip
                        noOfLines={supportedMimeType?.length}
                        label={supportedMimeType?.join("\n")}
                      >
                        <Text fontSize="xs" color="gray.700">
                          {t("Supported Files")}
                        </Text>
                      </Tooltip>
                    )}
                  </VStack>
                </Box>
              )}
            </Upload>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UploadDialog;
