import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useConfig } from "@hooks/config";
import useLogging from "@hooks/logging";
import { throttleFn } from "@utils/utilities";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineAccessTime, MdOutlineRefresh } from "react-icons/md";
import { useSelector } from "react-redux";

import store from "@/store/store";

let _timeout: NodeJS.Timeout | null = null;
const sessionTimeout =
  parseInt(import.meta.env.VITE_CHAT_SESSION_TIMEOUT || "1800") * 1000;
const SessionClosedSheet = () => {
  const { t } = useTranslation();
  const config = useConfig();
  const logging = useLogging();
  const lastUpdate = useSelector(store.select.chatModel.lastUpdate);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeSession = () => {
    store.dispatch.chatModel.closeSession();
    onOpen();
  };

  const getTimeout = (timeout: number) => {
    return setTimeout(() => {
      logging.debug(
        "❌ Session timeout reached! Showing session closed sheet."
      );
      closeSession();
    }, timeout);
  };

  useEffect(() => {
    const now = Date.now();
    if (!lastUpdate) {
      logging.debug("No updates yet!, skipping session close timeout.");
      return;
    }

    const timeoutLeft = sessionTimeout - (now - lastUpdate);

    if (_timeout) {
      clearTimeout(_timeout);
      onClose();
      logging.debug("Refreshing session timeout to ", sessionTimeout, "ms");
    } else {
      logging.debug("Setting timeout for session to ", sessionTimeout, "ms");
    }
    logging.debug("⏰ Timeout left: ", timeoutLeft, "ms");
    _timeout = getTimeout(timeoutLeft);
    return () => {
      logging.debug("🧹 Clean up session closed sheet");
      if (_timeout) clearTimeout(_timeout);
    };
  }, [lastUpdate]);

  const restartChat = throttleFn(() => {
    logging.debug("Restarting chat");
    store.dispatch.chatModel.restartChat(config.identity);
  }, 2000);

  return (
    <Drawer
      trapFocus={false}
      placement={"bottom"}
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent bg="brand.background">
        <DrawerHeader borderBottomWidth="1px">
          <HStack>
            <MdOutlineAccessTime />
            <Text>{t("Session Closed")}</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Text whiteSpace="pre-line">
            {t("SessionClosedMsg", {
              minutes: Math.round(sessionTimeout / 1000 / 60),
            })}
          </Text>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="solid"
            mr={3}
            w="full"
            onClick={restartChat}
            leftIcon={<MdOutlineRefresh />}
          >
            {t("Restart Session")}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SessionClosedSheet;
