import { Spinner } from "@chakra-ui/react";
import { useConfig } from "@hooks/config";
import Home from "@pages/home";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import store from "@/store/store";

import RealTimeConnectionManager from "./RealTimeConnectionManager";

const ChatConnection = () => {
  const hydrationFinished = useSelector(store.select.configModel.isHydrated);
  const { i18n } = useTranslation();
  const config = useConfig();

  useEffect(() => {
    if (config.language === "auto") {
      const language = navigator.language.split("-")[0];
      if (language !== i18n.language && i18n.languages.includes(language)) {
        i18n.changeLanguage(language);
        store.dispatch.configModel.setLanguage(language);
      }
    } else if (
      config.language !== i18n.language &&
      i18n.languages.includes(config.language)
    ) {
      i18n.changeLanguage(config.language);
      store.dispatch.configModel.setLanguage(config.language);
    }
  }, []);

  if (!hydrationFinished) {
    return <Spinner />;
  }

  return (
    <>
      <Home />
      <RealTimeConnectionManager />
    </>
  );
};

export default ChatConnection;
