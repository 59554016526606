import {
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { privacyPolicyUrl } from "@constants";
import { useConfig, useMessenger } from "@hooks/config";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import store from "@/store/store";

import CloseChatButton from "./CloseChatButton";
import LanguageUpdate from "./LanguageSwitcher";

const emailRegex = /^((?!\.)[\w\-_.]*[^.])(@[\w\-_]+)(\.\w+(\.\w+)?[^.\W])$/i;

type UserDetail = {
  name: string;
  email: string;
};

type INameDialogBodyProps = UserDetail & {
  closeDialog: () => void;
};

const NameDialogBody = ({ closeDialog, name, email }: INameDialogBodyProps) => {
  const { t } = useTranslation();
  const config = useConfig();
  const customPrivacyPolicy = useSelector(
    store.select.configModel.privaryPolicyUrl
  );

  const validateName = (value: string) => {
    if (!value) {
      return "Name is required";
    }
    if (value.trim().match(/Agent|User|System/g)) {
      return "A valid name is required.";
    }
  };

  const validateEmail = (value: string) => {
    const valid = emailRegex.test(value ?? "");

    if (!valid) {
      return "A valid email is required.";
    }
  };

  return (
    <Formik
      initialValues={{ name, email } as UserDetail}
      onSubmit={async (values: UserDetail) => {
        closeDialog();
        store.dispatch.chatModel.startChat({
          userInfo: values,
          session: config.session,
          identity: config.identity,
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form id="name-dialog">
          <VStack gap={3}>
            <Field name="name" validate={validateName}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name}>
                  <FormLabel>{t("Name")}</FormLabel>
                  <Input
                    className="Name-Dialog-name"
                    borderRadius={"6px"}
                    focusBorderColor="brand.secondary"
                    placeholder={t("Name")}
                    autoComplete="on"
                    required={true}
                    value={field.value ?? ""}
                    {...field}
                  />
                  <FormErrorMessage>{t(form.errors.name)}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email" validate={validateEmail}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.email}>
                  <FormLabel>{t("Email")}</FormLabel>
                  <Input
                    className="Name-Dialog-email"
                    borderRadius={"6px"}
                    focusBorderColor="brand.secondary"
                    placeholder={t("Email")}
                    autoComplete="on"
                    type={"email"}
                    required={true}
                    value={field.value ?? ""}
                    {...field}
                  />
                  <FormErrorMessage>{t(form.errors.email)}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Text
              mt={2}
              alignSelf={"flex-start"}
              fontSize={"10px"}
              color={"purple.400"}
            >
              {t("By continuing, you agree to the Terms and Conditions and") +
                " "}
              <chakra.span
                textDecor={"underline"}
                as={Link}
                target="_blank"
                href={customPrivacyPolicy ?? privacyPolicyUrl}
              >
                {t("Privacy Policy")}
              </chakra.span>
            </Text>
            <Button
              alignSelf={"flex-end"}
              className="Name-Dialog-submit"
              mt={4}
              borderRadius={"3px"}
              color="brand.onSecondary"
              bg="brand.primary"
              _hover={{
                bg: "brand.primary",
              }}
              size={"sm"}
              isLoading={isSubmitting}
              type="submit"
            >
              {t("Let's Chat")}
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

type NameDialogProps = {
  onClose?: () => void;
};
let dialogStepCompleted = false;
const NameDialog = ({ onClose: onDialogClose }: NameDialogProps) => {
  const { t } = useTranslation();
  const config = useConfig();
  const { name, email } = useSelector(store.select.chatModel.userInfo);
  const skipLogin = useSelector(store.select.configModel.skipLogin);
  const manualStart = useConfig().manualStart;
  const messenger = useMessenger();

  const startChat = () => {
    if (!manualStart || !(config.skipLogin || skipLogin)) return;
    store.dispatch.chatModel.startChat({
      userInfo: { name, email },
      session: config.session,
      identity: config.identity,
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  const init = () => {
    if (!(config.skipLogin || skipLogin) && (!name || !email)) {
      onOpen();
    } else if (!manualStart) {
      store.dispatch.chatModel.startChat({
        userInfo: { name, email },
        session: config.session,
        identity: config.identity,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = messenger.on("start", startChat);
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (dialogStepCompleted) return;
    init();
    dialogStepCompleted = true;
  }, [name, email, skipLogin]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      isCentered={true}
      closeOnOverlayClick={false}
      returnFocusOnClose={true}
      onCloseComplete={onDialogClose}
      motionPreset="slideInLeft"
      size={"md"}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent m="4">
        <ModalHeader>
          <HStack justifyContent={"space-between"}>
            <Heading size={"16px"}>{t("Welcome")}</Heading>
            <ButtonGroup>
              <LanguageUpdate color="brand.primary" />
              <CloseChatButton />
            </ButtonGroup>
          </HStack>
          <Heading mt="1" size="sm" fontWeight="400">
            {t("Please fill these details to continue")}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Spacer />
          <NameDialogBody
            name={name ?? ""}
            email={email ?? ""}
            closeDialog={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NameDialog;
