/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
type ColorScheme = {
  primary: string;
  primaryVariant: string;
  secondary: string;
  secondaryVariant: string;
  onSecondary: string;
  background: string;
  appBar: string;
  text: string;
  chatBubbleBackground: string;
  chatBubbleText: string;
  otherChatBubbleBackground: string;
  otherChatBubbleText: string;
};

export function generateMaterialColorScheme(seedColor: string): ColorScheme {
  // Convert seed color to HSL
  const seedHSL = hexToHSL(seedColor);

  // Generate primary color by adjusting hue
  const primaryHue = (seedHSL.h + 180) % 360;
  const primaryColor = hslToHex({ h: primaryHue, s: seedHSL.s, l: seedHSL.l });

  // Generate primary variant by adjusting saturation
  const primaryVariantSaturation = Math.max(seedHSL.s - 20, 0);
  const primaryVariantColor = hslToHex({
    h: primaryHue,
    s: primaryVariantSaturation,
    l: seedHSL.l,
  });

  // Generate secondary color by adjusting hue
  const secondaryHue = (primaryHue + 180) % 360;
  const secondaryColor = hslToHex({ h: secondaryHue, s: seedHSL.s, l: seedHSL.l });

  // Generate secondary variant by adjusting saturation
  const secondaryVariantSaturation = Math.max(seedHSL.s - 10, 0);
  const secondaryVariantColor = hslToHex({
    h: secondaryHue,
    s: secondaryVariantSaturation,
    l: seedHSL.l,
  });

  // Generate background color by adjusting lightness
  const backgroundLightness = seedHSL.l > 50 ? seedHSL.l - 40 : seedHSL.l + 40;
  const backgroundColor = hslToHex({ h: seedHSL.h, s: seedHSL.s, l: backgroundLightness });

  // Generate app bar color by adjusting lightness
  const appBarLightness = seedHSL.l > 50 ? seedHSL.l - 30 : seedHSL.l + 20;
  const appBarColor = hslToHex({ h: seedHSL.h, s: seedHSL.s, l: appBarLightness });

  const textColor = getBrightness(backgroundColor) > 127 ? "#000000" : "#ffffff";

  // Generate chat bubble background color
  const chatBubbleBackgroundLightness = seedHSL.l > 50 ? seedHSL.l - 15 : seedHSL.l + 15;
  const chatBubbleBackground = hslToHex({
    h: seedHSL.h,
    s: seedHSL.s,
    l: chatBubbleBackgroundLightness,
  });

  // Generate chat bubble text color
  const chatBubbleText = getBrightness(chatBubbleBackground) > 127 ? "#000000" : "#ffffff";

  // Generate chat bubble background color for other party's messages
  const otherChatBubbleBackground = getContrastingColor(chatBubbleBackground);

  // Generate chat bubble text color for other party's messages
  const otherChatBubbleText =
    getBrightness(otherChatBubbleBackground) > 127 ? "#000000" : "#ffffff";

  // Generate On Secondary color
  const onSecondary = getBrightness(secondaryColor) > 127 ? "#000000" : "#ffffff";

  const colorScheme: ColorScheme = {
    primary: primaryColor,
    primaryVariant: primaryVariantColor,
    secondary: secondaryColor,
    secondaryVariant: secondaryVariantColor,
    onSecondary: onSecondary,
    background: backgroundColor,
    appBar: appBarColor,
    text: textColor,
    chatBubbleBackground: chatBubbleBackground,
    chatBubbleText: chatBubbleText,
    otherChatBubbleBackground: otherChatBubbleBackground,
    otherChatBubbleText: otherChatBubbleText,
  };

  return colorScheme;
}

function getContrastingColor(color: string): string {
  const rgb = hexToRGB(color)!;
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  const contrastingBrightness = brightness > 127 ? 0 : 255;
  const hexContrastingColor = rgbToHex({
    r: contrastingBrightness,
    g: contrastingBrightness,
    b: contrastingBrightness,
  });
  return hexContrastingColor;
}

// Helper function to get the brightness value of a color
function getBrightness(color: string): number {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return (r * 299 + g * 587 + b * 114) / 1000;
}

// Helper function to convert hexadecimal color to HSL
function hexToHSL(hex: string) {
  const rgb = hexToRGB(hex)!;
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0;
  const l: number = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
}

// Helper function to convert HSL color to hexadecimal
function hslToHex(hsl: { h: number; s: number; l: number }) {
  const h = hsl.h / 360;
  const s = hsl.s / 100;
  const l = hsl.l / 100;

  const hueToRGB = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);
  }

  return rgbToHex({ r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) });
}

// Helper function to convert hexadecimal color to RGB
function hexToRGB(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = fullHexRegex.exec(hex) || shorthandRegex.exec(hex);

  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }

  return null;
}

// Helper function to convert RGB color to hexadecimal
function rgbToHex(rgb: { r: number; g: number; b: number }) {
  return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}
