import { generate } from "short-uuid";

const _UQID_KEY = "_uq";

export function setUniqueIdentity(id: string | null) {
  if (!id) {
    localStorage.removeItem(_UQID_KEY);
    return null;
  }
  localStorage.setItem(_UQID_KEY, id);
  return id;
}

export function getUniqueIdentity() {
  let uqid = localStorage.getItem(_UQID_KEY);

  if (uqid) {
    return uqid;
  }

  const array = new Uint16Array(1);
  window.crypto.getRandomValues(array);
  const uuid = generate();
  uqid = `${uuid}-${array[0]}`;

  localStorage.setItem(_UQID_KEY, uqid);

  return uqid;
}
