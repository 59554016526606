import {
  Card,
  CardBody,
  Divider,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IMessageLinkPreview } from "@CustomTypes/message.type";

type IPreviewCardProps = {
  preview: IMessageLinkPreview;
};

const PreviewCard = ({ preview }: IPreviewCardProps) => {
  return (
    <Link key={preview.id} target="_blank" href={preview.link} isExternal>
      <Card
        aspectRatio={"16 / 9"}
        m={0}
        p={0}
        width="100%"
        overflow="hidden"
        variant="filled"
        direction={"row"}
      >
        <CardBody m={0} p={0}>
          <HStack minH={"100%"} maxH={"100%"} spacing="1">
            {preview.image && (
              <Image
                aspectRatio={"0.80"}
                objectFit="cover"
                objectPosition={"center"}
                src={preview.image}
                alt={preview.title}
                maxW={"45%"}
                _hover={{
                  maxW: "55%",
                }}
                transition="max-width 0.2s ease-in"
              />
            )}
            <VStack
              spacing={1}
              p={1}
              justifyContent={"start"}
              alignItems="start"
            >
              {preview.title && (
                <Text
                  fontSize="xs"
                  noOfLines={2}
                  fontWeight="semibold"
                  textOverflow={"ellipsis"}
                  title={preview.title}
                >
                  {preview.title}
                </Text>
              )}
              {preview.description && (
                <Text
                  title={preview.description}
                  noOfLines={4}
                  fontSize="xs"
                  textOverflow={"ellipsis"}
                >
                  {preview.description}
                </Text>
              )}
            </VStack>
          </HStack>
        </CardBody>
      </Card>
    </Link>
  );
};

type Props = {
  previews?: IMessageLinkPreview[];
};

const LinkPreview = ({ previews }: Props) => {
  const available = previews?.filter(
    (value) => value.image || value.title || value.description
  );

  if (!available || available.length === 0) {
    return null;
  }

  return (
    <>
      <Divider my="2" />
      <VStack alignItems={"stretch"}>
        {available.map((preview) => (
          <PreviewCard preview={preview} key={preview.id} />
        ))}
      </VStack>
    </>
  );
};

export default LinkPreview;
