import {
  Box,
  HStack,
  Image,
  ScaleFade,
  StyleProps,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import store from "@/store/store";

import TypingLoader from "./TypingLoader";

const ChatTypingAction = (style: StyleProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const action = useSelector(store.select.chatActionModel.action);
  const ownerInfo = useSelector(store.select.configModel.owner);
  const { t } = useTranslation();
  useEffect(() => {
    if (action) {
      onOpen();
    } else {
      onClose();
    }
  }, [action]);

  return (
    <HStack
      color="brand.text"
      py={1}
      alignItems={"center"}
      justifyContent="center"
      width="100%"
      position={"relative"}
      {...style}
    >
      <ScaleFade
        in={isOpen}
        initialScale={0}
        style={{
          position: "absolute",
          left: 0,
          top: 2,
        }}
      >
        <HStack position="absolute" left="0" top="0">
          <Text fontSize="xs" textAlign="left">
            {action && t(action)}
          </Text>
          <TypingLoader />
        </HStack>
      </ScaleFade>
      {!(ownerInfo?.is_reseller || ownerInfo?.client_of) && (
        <HStack borderRadius={5} fontSize="xs" justifyContent={"center"}>
          <Tooltip label="Powered By Caleida">
            <a href="https://www.caleida.io" target="_blank" rel="noreferrer">
              {t("Powered by")}&nbsp;
              <Box
                display={"inline-block"}
                px={1.5}
                borderRadius={"full"}
                bg="rgba(109, 75, 150, 0.5)"
              >
                <Image
                  display="inline"
                  src="/CaleidaLogo.png"
                  alt="Caleida"
                  height={"0.6rem"}
                  my="auto"
                />
              </Box>
            </a>
          </Tooltip>
        </HStack>
      )}
    </HStack>
  );
};

export default ChatTypingAction;
