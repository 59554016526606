import { envMode, IMode } from "@configs/index";
import {
  init,
  RematchDispatch,
  RematchRootState,
  RematchStore,
} from "@rematch/core";
import immerPlugin from "@rematch/immer";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import persistPlugin from "@rematch/persist";
import selectPlugin from "@rematch/select";
// import updatedPlugin, { ExtraModelsFromUpdated } from "@rematch/updated";
import storage from "redux-persist-indexeddb-storage";

import { IRootModel, models } from "./model";

type IFullModel = ExtraModelsFromLoading<IRootModel, { type: "full" }>;
// & ExtraModelsFromUpdated<IRootModel>;

const persistConfig = () => {
  const query = new URLSearchParams(window.location.search);
  const embedId = query.get("embedId");
  const key = "storage-" + embedId;
  return {
    key: key,
    storage: storage(key),
    whitelist: ["chatModel"],
  };
};

export const store: RematchStore<IRootModel, IFullModel> = init<
  IRootModel,
  IFullModel
>({
  models,
  plugins: [
    selectPlugin(),
    // updatedPlugin(),
    loadingPlugin({ type: "full" }),
    immerPlugin(),
    persistPlugin(persistConfig(), undefined, undefined, () => {
      store.dispatch.configModel.setHydrated();
    }),
  ],
  redux: {
    devtoolOptions: {
      disabled: [IMode.production, IMode.staging].includes(envMode),
    },
  },
});

export type IStore = typeof store;
export type IDispatch = RematchDispatch<IRootModel>;
export type IRootState = RematchRootState<IRootModel, IFullModel>;

export default store;
