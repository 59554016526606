import { IconButton, Tooltip } from "@chakra-ui/react";
import { useConfig, useMessenger } from "@hooks/config";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const CloseChatButton = () => {
  const config = useConfig();
  const messenger = useMessenger();
  const { t } = useTranslation();

  const closeChat = () => {
    messenger.sendMessage({
      event: "command",
      payload: {
        value: "toggleChat",
      },
    });
  };

  if (!config.enableCloseBtn) {
    return <></>;
  }

  return (
    <Tooltip label={t("Close Chat")} hasArrow>
      <IconButton
        aria-label={t("Close Chat")}
        icon={<MdClose size={"22px"} />}
        variant={"solid"}
        colorScheme="blackAlpha"
        isRound={true}
        size={"sm"}
        onClick={closeChat}
      />
    </Tooltip>
  );
};

export default CloseChatButton;
