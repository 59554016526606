import "./style.css";

import { Box } from "@chakra-ui/react";

const TypingLoader = () => {
  return (
    <Box width="40px">
      <div className="typing-loader"></div>
    </Box>
  );
};

export default TypingLoader;
