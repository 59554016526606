import {
  Avatar,
  AvatarBadge,
  ButtonGroup,
  HStack,
  Spacer,
  StyleProps,
  Text,
  useToken,
} from "@chakra-ui/react";
import CloseChatButton from "@components/UI/CloseChatButton";
import LanguageUpdate from "@components/UI/LanguageSwitcher";
import NewChatButton from "@components/UI/NewChatButton";
import { defaultLogoUrl } from "@constants";
import { getTextColor } from "@utils/utilities";
import { useSelector } from "react-redux";

import store from "@/store/store";

function AppBar(props: StyleProps) {
  const companyLogo = useSelector(store.select.configModel.companyLogo);
  const title = useSelector(store.select.configModel.title);
  const [appBar] = useToken("colors", ["brand.appBar"]);

  return (
    <HStack
      color="brand.text"
      bg={"brand.appBar"}
      p="2"
      borderTopRadius="inherit"
      shadow="md"
      width={"inherit"}
      position="relative"
      zIndex={11}
      {...props}
    >
      <Avatar
        name={title ?? "Caleida Chat"}
        src={companyLogo || defaultLogoUrl}
        size="sm"
      >
        <AvatarBadge boxSize="1.2em" bg="green.500" />
      </Avatar>

      {title && (
        <Text color={getTextColor(appBar)} fontWeight={"medium"}>
          {title}
        </Text>
      )}

      <Spacer />

      <ButtonGroup>
        <NewChatButton />
        <LanguageUpdate color="brand.text" />
        <CloseChatButton />
      </ButtonGroup>
    </HStack>
  );
}

export default AppBar;
