import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { defaultTheme, themeConfig } from "@configs/theme";
import { defaultLogoUrl } from "@constants";
import { useConfig, useMessenger } from "@hooks/config";
import { generateMaterialColorScheme } from "@utils/theme_generator";
import { sleep } from "@utils/utilities";
import { ReactNode, useEffect, useMemo } from "react";
import { ImSpinner2 } from "react-icons/im";
import { useSelector } from "react-redux";

import store, { IRootState } from "@/store/store";

type Props = {
  children: ReactNode | ReactNode[];
};

let configSetupCompleted = false;

const ThemingContext = ({ children }: Props) => {
  const config = useConfig();
  const messanger = useMessenger();

  const fetchingTheme = useSelector(
    (state: IRootState) => state.loading.effects.configModel.fetchTheme.loading
  );
  const themeData = useSelector(store.select.configModel.theme);

  const fetchConfig = async (projectId: string) => {
    const config = await store.dispatch.configModel.fetchTheme(projectId);

    const theme = config?.theme;
    messanger.sendMessage({
      event: "config",
      payload: {
        logo: config?.clogo || window.location.origin + defaultLogoUrl,
        bubbleBg: theme?.chatBubbleBackground,
        bubbleFg: theme?.chatBubbleText,
      },
    });

    if (theme?.showPopup && theme.popupMessage) {
      const messageBlocks = theme.popupMessage.split("\n\n");
      for (const message of messageBlocks) {
        await sleep(1000);
        messanger.sendMessage({
          event: "message",
          payload: {
            text: message,
          },
        });
      }
    }
  };

  const theme = useMemo(() => {
    const scheme = themeData.themeBase
      ? generateMaterialColorScheme(themeData.themeBase)
      : defaultTheme;

    const colors = {
      brand: {
        primary: themeData.primary || scheme.primary,
        primaryVariant: themeData.primaryVariant || scheme.primaryVariant,
        secondary: themeData.secondary || scheme.secondary,
        secondaryVariant: themeData.secondaryVariant || scheme.secondaryVariant,
        background: themeData.background || scheme.background,
        appBar: themeData.appBar || scheme.appBar,
        text: themeData.text || scheme.text,
        chatBubbleBackground:
          themeData.chatBubbleBackground || scheme.chatBubbleBackground,
        chatBubbleText: themeData.chatBubbleText || scheme.chatBubbleText,
        otherChatBubbleBackground:
          themeData.otherChatBubbleBackground ||
          scheme.otherChatBubbleBackground,
        otherChatBubbleText:
          themeData.otherChatBubbleText || scheme.otherChatBubbleText,
        onSecondary: themeData.onSecondary || scheme.onSecondary,
      },
    };
    const customTheme = extendTheme({
      colors,
      themeConfig,
      config: {
        cssVarPrefix: "caleida-chat",
      },
    });
    return customTheme;
  }, [themeData]);

  useEffect(() => {
    if (configSetupCompleted) return;
    fetchConfig(config.projectId);
    configSetupCompleted = true;
  }, []);

  if (fetchingTheme) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          color: "white",
        }}
      >
        <ImSpinner2 size="28" className="caleida-spin" />
      </div>
    );
  }

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default ThemingContext;
