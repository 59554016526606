/* eslint-disable @typescript-eslint/naming-convention */
import {
  IChatAction,
  IMessageRequestPayload,
  IResponseMessage,
} from "@CustomTypes/message.type";
import { createModel } from "@rematch/core";

import { IRootModel } from ".";

type IResponseModelType = {
  partialMessage?: IResponseMessage;
  activePayload?: IMessageRequestPayload;
  finalMessage?: IResponseMessage;
  waiting: boolean;
};

const initialState: IResponseModelType = {
  waiting: false,
};

const responseModel = createModel<IRootModel>()({
  state: initialState,
  selectors: (slice) => ({
    activePayload() {
      return slice((state) => state.activePayload);
    },
    isWaiting() {
      return slice((state) => state.waiting);
    },
  }),
  reducers: {
    resetAll() {
      return initialState;
    },
    setupConnection(state, data: IMessageRequestPayload) {
      state.waiting = true;
      state.activePayload = data;
    },
  },
  effects: (dispatch) => ({
    updateChatAction(data: IChatAction) {
      dispatch.chatActionModel.handleChatAction(data);
    },
    settleAll(_: void, state) {
      const message = state.responseModel.finalMessage;
      if (message) dispatch.chatModel.receivedMessage(message);
      dispatch.chatActionModel.handleChatAction(null);
      dispatch.responseModel.resetAll();
    },
  }),
});

export default responseModel;
