export enum IMode {
  local = "testing-local",
  development = "development",
  staging = "staging",
  production = "production",
}
export const envMode: IMode = import.meta.env.MODE as IMode;

export function getValue<IT>(local: IT, dev: IT, stage: IT, prod: IT) {
  switch (envMode) {
    case IMode.local:
      return local;
    case IMode.production:
      return prod;
    case IMode.staging:
      return stage;
    case IMode.development:
    default:
      return dev;
  }
}
