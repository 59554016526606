import { useConfig } from "./config";

const logging = (mode: string, ...args: unknown[]) => {
  switch (mode) {
    case "info":
      console.info(...args);
      break;
    case "warn":
      console.warn(...args);
      break;
    case "error":
      console.error(...args);
      break;
    case "debug":
    default:
      console.debug(`DEBUG: %c${args[0]}`, "color: #228b22", ...args.slice(1));
      break;
  }
};

const info = (...args: unknown[]) => logging("info", ...args);
const warn = (...args: unknown[]) => logging("warn", ...args);
const error = (...args: unknown[]) => logging("error", ...args);
const debug = (...args: unknown[]) => logging("debug", ...args);

const useLogging = () => {
  const config = useConfig();

  if (config.debug) {
    return { info, warn, error, debug };
  }
  return { info, warn, error, debug: () => undefined };
};

export default useLogging;
