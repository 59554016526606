import { BotConfigData } from "@CustomTypes/config.type";
import axios from "axios";

class ConfigService {
  private service_url: string;
  private project: string;

  constructor(host: string, project: string) {
    this.service_url = host;
    this.project = project;
  }

  async getConfig(): Promise<BotConfigData | undefined> {
    try {
      const url = `${this.service_url}/bots/projects/${this.project}/theme/`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default ConfigService;
