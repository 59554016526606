import i18n from "i18next";
import TimeAgo from "javascript-time-ago";
import english from "javascript-time-ago/locale/en.json";
import spanish from "javascript-time-ago/locale/es.json";
import { initReactI18next } from "react-i18next";

import en from "./locales/en/translations.json";
import es from "./locales/es/translations.json";
const lang = localStorage.getItem("lang") || "es";

TimeAgo.addDefaultLocale(spanish);
TimeAgo.addLocale(english);

i18n.use(initReactI18next).init({
  fallbackLng: "es",
  lng: lang,
  resources: {
    en: {
      translations: en,
    },
    es: {
      translations: es,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  supportedLngs: ["en", "es"],
  debug: false,
});

i18n.languages = ["en", "es"];

export default i18n;
