import { useContext } from "react";

import { ConfigContext } from "@/contexts/ConfigContext";
import { MessageContext } from "@/contexts/MessageListeners";

export const useConfig = () => {
  const config = useContext(ConfigContext);
  if (!config) throw new Error("Config is not defined");

  return config;
};

export const useMessenger = () => {
  const messengerContext = useContext(MessageContext);
  if (!messengerContext) throw new Error("MessageContext not found.");
  return messengerContext;
};
