import { IconButton, Tooltip } from "@chakra-ui/react";
import { useConfig } from "@hooks/config";
import useLogging from "@hooks/logging";
import { throttleFn } from "@utils/utilities";
import { useTranslation } from "react-i18next";
import { MdOutlineRefresh } from "react-icons/md";

import store from "@/store/store";

const NewChatButton = () => {
  const config = useConfig();
  const logging = useLogging();
  const { t } = useTranslation();

  if (!config.restartAllowed || config.session) {
    return <></>;
  }

  const restartChat = throttleFn(() => {
    logging.debug("Restarting chat");
    store.dispatch.chatModel.closeSession();
    store.dispatch.chatModel.restartChat(config.identity);
  }, 2000);

  return (
    <Tooltip label={t("New Chat")} hasArrow>
      <IconButton
        isDisabled={!config.restartAllowed}
        aria-label={t("New Chat")}
        icon={<MdOutlineRefresh size={"22px"} />}
        variant={"solid"}
        colorScheme="blackAlpha"
        isRound={true}
        size={"sm"}
        onClick={restartChat}
      />
    </Tooltip>
  );
};

export default NewChatButton;
