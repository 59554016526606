import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { IMessage } from "@CustomTypes/message.type";
import { useConfig } from "@hooks/config";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { MdSend } from "react-icons/md";
import short from "short-uuid";

import store from "@/store/store";

const phoneRegex = /(\+?\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/i;

const PhoneDialogBody = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const validatePhone = (value: string) => {
    const valid = phoneRegex.test(value);

    if (!valid) {
      return "A valid phone number is required.";
    }
  };

  return (
    <Formik
      initialValues={{ phone: "" }}
      onSubmit={async (values) => {
        const message: IMessage = {
          id: short.generate(),
          phone: values.phone,
          text: values.phone,
          type: "Phone",
          role: "User",
          timestamp: new Date().getTime(),
          identity: config.identity,
        };
        await store.dispatch.chatModel.sendMessageAndWait(message);
        onClose();
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack>
            <Field name="phone" type="tel" validate={validatePhone}>
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.phone}>
                  <Input
                    borderRadius={"full"}
                    focusBorderColor="brand.secondary"
                    {...field}
                    placeholder={t("Phone Number")}
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              width="100%"
              rightIcon={<MdSend />}
              borderRadius={"full"}
              color="brand.onSecondary"
              bg="brand.primary"
              _hover={{
                bg: "brand.primary",
              }}
              isLoading={isSubmitting}
              type="submit"
            >
              {t("Send")}
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

type IProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  canClose?: boolean;
};

const PhoneDialog = ({ isOpen, onClose, canClose }: IProps) => {
  // const config = useContext(ConfigContext);
  const { t } = useTranslation();
  return (
    <Modal
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent m="4">
        {canClose && (
          <ModalCloseButton
            onClick={onClose}
            position="absolute"
            right="2"
            top="2"
          />
        )}
        <ModalHeader>
          <Heading size="md">{t("Send Phone Number")}</Heading>
          <Heading mt="1" size="xs" fontWeight="medium">
            {t("Please enter your phone number")}
          </Heading>
        </ModalHeader>
        <ModalBody pt="0">
          <PhoneDialogBody onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PhoneDialog;
