import "../i18n/index";

import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { IServiceConfig } from "@CustomTypes/message.type";
import { clamp } from "framer-motion";
import { createContext, ReactNode } from "react";
import short from "short-uuid";
export const ConfigContext = createContext<IServiceConfig | null>(null);

type IProps = {
  children: ReactNode;
};

const ConfigContextProvider = ({ children }: IProps) => {
  const query = new URLSearchParams(window.location.search);
  const projectId = query.get("project_id");
  const verifyKey = query.get("verify_key") || query.get("verification_token");
  const embedId = query.get("embedId");

  if (!projectId || !verifyKey || !embedId) {
    return (
      <Card fontFamily={"sans-serif"}>
        <CardHeader>
          <h1>Invalid Configuration</h1>
        </CardHeader>
        <CardBody>
          <p>Project ID or Verify Key or embedId is not provided.</p>
        </CardBody>
      </Card>
    );
  }

  const config: IServiceConfig = {
    projectId: projectId,
    verifyKey: verifyKey,
    endpoint: import.meta.env.VITE_CHAT_SERVICE_URL,
    restartAllowed: query.get("restartAllowed") === "true",
    language: query.get("language") || "auto",
    enableCloseBtn: query.get("closeBtn") === "true",
    debug: query.get("debug") === "true",
    containerId: `caleida-chat-container-${short.generate()}`,
    typingDelay: clamp(10, 500, parseInt(query.get("typingDelay") || "100")),
    otid: query.get("otid"),
    wfid: query.get("wfid"),
    manualStart: query.get("manualStart") === "true",
    session: query.get("session"),
    identity: query.get("identity"),
    skipLogin: query.get("skipLogin") === "true",
  };

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
