import { Models } from "@rematch/core";

import chatModel from "./chat.model";
import chatActionModel from "./chatAction.model";
import configModel from "./config.model";
import responseModel from "./response.model";

export interface IRootModel extends Models<IRootModel> {
  chatModel: typeof chatModel;
  chatActionModel: typeof chatActionModel;
  responseModel: typeof responseModel;
  configModel: typeof configModel;
}

export const models: IRootModel = {
  chatModel,
  chatActionModel,
  responseModel,
  configModel,
};
