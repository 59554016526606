// 1. Import the extendTheme function
import type { ThemeConfig } from "@chakra-ui/react";
import { BotThemeData } from "@CustomTypes/config.type";

// 2. Extend the theme to include custom colors, fonts, etc
export const defaultTheme: BotThemeData = {
  text: "#000000",
  appBar: "#74419d",
  primary: "#412c53",
  secondary: "#412c53",
  background: "#e4d9eb",
  onSecondary: "#ffffff",
  chatBubbleText: "#ffffff",
  primaryVariant: "#e4af53",
  secondaryVariant: "#4986ee",
  otherChatBubbleText: "#ffffff",
  chatBubbleBackground: "#c8b3d8",
  otherChatBubbleBackground: "#9067b1",
};

export const themeConfig: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
  cssVarPrefix: "caleida",
};
