// eslint-disable-next-line simple-import-sort/imports
import { firebaseAuth, firebaseDb } from "@configs/firebaseConfig";
import { child, DataSnapshot, onChildAdded, onValue } from "@firebase/database";
import { useConfig } from "@hooks/config";
import { getUniqueIdentity } from "@utils/identity";
import { signInWithCustomToken } from "firebase/auth";
import { ref } from "firebase/database";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import ChatService from "@/services/chat.service";
import store from "@/store/store";
import useLogging from "@hooks/logging";

let rendered = false;
let connectionTimestamp: number | null = null;

const RealTimeConnectionManager = () => {
  const config = useConfig();
  const logging = useLogging();
  const chatId = useSelector(store.select.chatModel.activeChat);
  const [autoMode, setAutoMode] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const identity = config.identity ?? getUniqueIdentity();
  const chatService = new ChatService(
    config.endpoint,
    config.projectId,
    identity
  );

  const login = async () => {
    logging.debug("RealTimeConnectionManager: Logging in");
    const response = await chatService.getToken();
    logging.debug(
      "RealTimeConnectionManager: Login response: " + JSON.stringify(response)
    );
    if (response) {
      await signInWithCustomToken(firebaseAuth, response?.token);
      setLoggedIn(true);
      logging.debug("RealTimeConnectionManager: Logged in ✅");
    }
  };

  useEffect(() => {
    if (rendered) return;
    login();
    rendered = true;
  }, []);

  const baseRef = useMemo(() => {
    if (!chatId) return;
    const project = config.projectId;
    const path = `/projects/${project}/chats/web/${chatId}-${identity}/`;
    logging.debug("RealTimeConnectionManager: Initializing Base Ref: " + path);

    return ref(firebaseDb, path);
  }, [chatId]);

  const onAgentStateChange = (snapshot: DataSnapshot) => {
    const auto: boolean = snapshot.val();
    logging.debug("AI Agent Mode: " + auto);
    setAutoMode(auto);
  };

  const onNewAgentMessage = (snapshot: DataSnapshot) => {
    const message = snapshot.val();

    const messageTs = new Date(message.timestamp * 1000);
    if (connectionTimestamp && connectionTimestamp > messageTs.getTime()) {
      return;
    }
    logging.debug("NEW MESSAGE PAYLOAD RECEIVED: ", message);
    if (message.gen && message.from_name) {
      logging.debug("NEW MESSAGE: " + JSON.stringify(snapshot.val()));
      store.dispatch.chatModel.receivedMessage({
        id: message.id,
        timestamp: messageTs,
        // fromName: message.from_name,
        // fromUser: message.from_user,
        text: message.text,
        role: "Agent",
        type: message.type,
        document: message.document,
        identity: config.identity,
        // image: message.image,
      });
    }
  };

  useEffect(() => {
    if (!baseRef || !loggedIn) return;

    const isAutoModeRef = child(baseRef, "state/auto");
    logging.debug("RealTimeConnectionManager: Initializing AI Agent Listener");
    const isAutoModeSubscription = onValue(
      isAutoModeRef,
      onAgentStateChange,
      console.error
    );
    return () => {
      logging.debug("RealTimeConnectionManager: Destroying AI Agent Listener");
      isAutoModeSubscription();
    };
  }, [baseRef, loggedIn, chatId]);

  useEffect(() => {
    if (!baseRef || !loggedIn) return;
    if (!autoMode) {
      logging.debug(
        "RealTimeConnectionManager:",
        " Initializing Human Agent Listener"
      );
      const historyRef = child(baseRef, "history");
      connectionTimestamp = new Date().getTime();
      const unsubscribe = onChildAdded(
        historyRef,
        onNewAgentMessage,
        console.error
      );
      return () => {
        connectionTimestamp = null;
        logging.debug(
          "RealTimeConnectionManager: ",
          "Destroying Human Agent Listener"
        );
        unsubscribe();
      };
    }
    return () => null;
  }, [autoMode, baseRef, loggedIn, chatId]);

  return <></>;
};

export default RealTimeConnectionManager;
