/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StyleProps, VStack } from "@chakra-ui/react";
import { BubblePlacement } from "@CustomTypes/chat_bubble.type";
import { IMessage } from "@CustomTypes/message.type";
import { useConfig, useMessenger } from "@hooks/config";
import { scrollToMessage } from "@utils/utilities";
import { UIEvent, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import store from "@/store/store";

import ChatItem from "./ChatItem";
import StreamReplyController from "./StreamReplyController";

let _autoScrolling = false;
let _stopAutoScroll = false;
const ChatContainer = (styles: StyleProps) => {
  const config = useConfig();
  const messages = useSelector(store.select.chatModel.messages);
  const scrollRef = useRef<HTMLDivElement>(null);
  const username = useSelector(store.select.chatModel.username);
  const botName = useSelector(store.select.configModel.botName);
  const messenger = useMessenger();

  const calculatePlacement = (
    message: IMessage,
    index: number,
    messages: IMessage[]
  ): BubblePlacement => {
    const hasNextIndex =
      index < messages.length - 1 && messages[index + 1].role === message.role;

    if (!hasNextIndex) {
      return "end";
    }
    return "top";
  };

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    if (_autoScrolling) return;
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const scrollHeightOffset = scrollHeight - clientHeight;
    // const canScroll = scrollHeightOffset > scrollTop;

    if (scrollHeightOffset - scrollTop > 30) {
      _stopAutoScroll = true;
    } else {
      _stopAutoScroll = false;
    }
  };

  const scrollToBottom = () => {
    const messageId = messages[messages.length - 1].id;
    scrollToMessage(
      messageId,
      32,
      scrollRef.current,
      (isScrolling) => (_autoScrolling = isScrolling)
    );
  };

  useEffect(() => {
    if (_stopAutoScroll) return;
    if (messages.length === 0) return;
    scrollToBottom();
    const off = messenger.on("start", scrollToBottom);
    return off;
  }, [messages]);

  return (
    <VStack
      width="100%"
      height={"100%"}
      flexDir="column"
      gap={2}
      spacing={0}
      overflowY="scroll"
      py={3}
      ref={scrollRef}
      id={config.containerId}
      onScroll={onScroll}
      {...styles}
    >
      <StreamReplyController />
      {messages.map((message, index, ref) => (
        <ChatItem
          username={
            message.role == "User"
              ? username || "Me"
              : (botName ?? "Cale").substring(0, 10)
          }
          key={index}
          message={message}
          placement={calculatePlacement(message, index, ref)}
        />
      ))}
    </VStack>
  );
};

export default ChatContainer;
