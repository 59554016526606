import { VStack } from "@chakra-ui/react";
import NameDialog from "@components/UI/NameDialog";
import SessionClosedSheet from "@components/UI/SessionClosedSheet";
import { useConfig } from "@hooks/config";

import AppBar from "./AppBar";
import HomeContent from "./HomeContent";

export default function Home() {
  const config = useConfig();

  return (
    <>
      <VStack height="100%" width="100%" spacing={0} borderRadius="inherit">
        <AppBar height="52px" />
        <HomeContent
          maxW={"100%"}
          height={"calc(100% - 52px)"}
          width="inherit"
        />
      </VStack>
      <NameDialog />
      {!config.session && <SessionClosedSheet />}
    </>
  );
}
