import { Avatar } from "@chakra-ui/react";
import { defaultLogoUrl } from "@constants";
import { useSelector } from "react-redux";

import store from "@/store/store";

type AgentAvatarProps = {
  name: string;
};
function AgentAvatar({ name }: AgentAvatarProps) {
  const botImage = useSelector(store.select.configModel.botImage);

  return (
    <Avatar name={name} src={botImage || defaultLogoUrl} size="xs"></Avatar>
  );
}

export default AgentAvatar;
