import "./App.css";

// import { ColorModeScript } from "@chakra-ui/react";
import { firebaseAuth } from "@configs/firebaseConfig";
// import customTheme from "@configs/theme";
import { indexedDBLocalPersistence, setPersistence } from "firebase/auth";
import { useEffect } from "react";
import { Provider } from "react-redux";

import ConfigContextProvider from "./contexts/ConfigContext";
import ChatConnection from "./contexts/ConnectionContext/ChatConnection";
import MessageListeners from "./contexts/MessageListeners";
import ThemingContext from "./contexts/ThemingContext";
import store from "./store/store";

function App() {
  useEffect(() => {
    setPersistence(firebaseAuth, indexedDBLocalPersistence);
  }, []);

  return (
    <Provider store={store}>
      <ConfigContextProvider>
        <MessageListeners>
          <ThemingContext>
            <ChatConnection />
          </ThemingContext>
        </MessageListeners>
      </ConfigContextProvider>
    </Provider>
  );
}

export default App;
