// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const config = import.meta.env.VITE_APP_FIREBASE_CONFIG;
if (!config) throw Error("Firebase config not found");
const firebaseConfig = JSON.parse(config || "{}");

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = initializeAuth(firebaseApp);
export const firebaseDb = getDatabase(firebaseApp);
