/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IChatAction } from "@CustomTypes/message.type";
import { createModel } from "@rematch/core";

import { IRootModel } from ".";

type IChatActionState = {
  currentAction?: IChatAction;
  timer?: NodeJS.Timeout;
};

const initialState: IChatActionState = {};

const chatActionModel = createModel<IRootModel>()({
  state: initialState, // initial state
  selectors: (slice) => ({
    action() {
      return slice((state) => {
        return state.currentAction?.type;
      });
    },
  }),
  reducers: {
    resetAction(state) {
      state.currentAction = undefined;
      state.timer = undefined;
    },
    setAction(state, action: IChatAction, timer: NodeJS.Timeout) {
      state.currentAction = action;
      state.timer = timer;
    },
  },
  effects: (dispatch) => ({
    handleChatAction(payload: IChatAction | null, state) {
      clearTimeout(state.chatActionModel.timer);
      if (!payload) {
        dispatch.chatActionModel.resetAction();
        return;
      }

      const timeout = setTimeout(() => {
        clearTimeout(state.chatActionModel.timer);
      }, payload.timeout);

      dispatch.chatActionModel.setAction(payload, timeout);
    },
  }),
});
export default chatActionModel;
