import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsTranslate } from "react-icons/bs";

import store from "@/store/store";

function LanguageUpdate({ color }: { color: string }) {
  const { t, i18n } = useTranslation();

  const changeLanguage = async (lng: string) => {
    await i18n.changeLanguage(lng);
    store.dispatch.configModel.setLanguage(lng);
  };
  return (
    <Menu placement="bottom-end" isLazy={true}>
      <Tooltip label={t("Change Language")}>
        <MenuButton
          icon={<BsTranslate />}
          colorScheme={"blackAlpha"}
          aria-label="change-lang"
          isRound={true}
          variant="solid"
          size={"sm"}
          as={IconButton}
        ></MenuButton>
      </Tooltip>
      <MenuList>
        <MenuItem color="black" onClick={() => changeLanguage("en")}>
          {t("English")}
        </MenuItem>
        <MenuItem color="black" onClick={() => changeLanguage("es")}>
          {t("Spanish")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default LanguageUpdate;
