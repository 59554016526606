import { ITokenResponse } from "@CustomTypes/token.type";
import axios from "axios";

class ChatService {
  private service_url: string;
  private project: string;
  private identity: string;

  constructor(host: string, project: string, identity: string) {
    this.service_url = host;
    this.project = project;
    this.identity = identity;
  }

  async getToken() {
    try {
      const url = `${this.service_url}/token/${this.project}/${this.identity}/`;
      const response = await axios.post<ITokenResponse>(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getTheme() {
    try {
      const url = `${this.service_url}/theme/${this.project}/`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

export default ChatService;
