/* eslint-disable @typescript-eslint/no-explicit-any */
export const scrollToMessage = (
  msgId?: string,
  delay?: number,
  parent?: Element | null,
  onScroll?: (isScrolling: boolean) => void
) => {
  if (!msgId) {
    return;
  }
  const id = "msg_$" + msgId;
  const element = document.getElementById(id);
  // console.log(delay, parent?.scrollHeight, element?.offsetTop);
  if (delay) {
    setTimeout(() => {
      if (parent) {
        // parent.scroll({
        //   top: element?.offsetTop,
        //   behavior: "smooth",
        // });
        onScroll && onScroll(true);
        parent.scroll({
          top: element?.scrollTop || parent.scrollHeight,
        });
        onScroll && onScroll(false);
      }
    }, delay);
    return;
  }

  if (parent) {
    onScroll && onScroll(true);
    parent.scrollTo({
      top: element?.scrollTop || parent.scrollHeight + 25,
      behavior: "smooth",
    });
    onScroll && onScroll(false);
  }
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export function throttle(callback: (...args: any[]) => void, delay: number) {
  let lastTime = 0;
  let timeout: NodeJS.Timeout | null = null;
  const eventQueue: ((...args: any[]) => void)[] = [];

  function processQueue() {
    // if the queue is not empty we will get the first item from the queue
    if (eventQueue.length > 0) {
      // first item from the queue
      const nextEvent = eventQueue.shift();
      if (nextEvent) {
        // execute the callback
        nextEvent();
        // update the last time the callback was called
        // so that we can calculate the time difference
        lastTime = new Date().getTime();
        // set the timeout again, we are calling this again
        // now we are are expecting the push has already happened
        // and the queue is not empty.
        timeout = setTimeout(processQueue, delay);
      }
    } else {
      timeout = null;
    }
  }

  return function (...args: any[]) {
    // We will push the callback to the queue
    eventQueue.push(() => {
      const now = new Date().getTime();
      if (!lastTime || now - lastTime >= delay) {
        callback(...args);
        lastTime = now;
      }
    });

    // if the timeout is not assigned we will assign it.
    // this is only going to be assigned once here as processQueue is
    // a recursive function and will be called again and again.
    if (!timeout) {
      timeout = setTimeout(processQueue, delay);
    }
  };
}

export function throttleFn(func: () => void, delay: number): () => void {
  let lastExecTime = 0;
  return function () {
    const currentExecTime = Date.now();
    if (currentExecTime - lastExecTime >= delay) {
      func();
      lastExecTime = currentExecTime;
    }
  };
}

export const utcTime = (date: number | Date) => {
  const d = new Date(date);
  return Date.UTC(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  );
};

export const clamp = (min: number, max: number, value: number) => {
  return Math.max(min, Math.min(max, value));
};

export const getTextColor = (surfaceColor: string) => {
  const hexToRgb = (hex: string) => ({
    r: parseInt(hex.substring(1, 3), 16) / 255,
    g: parseInt(hex.substring(3, 5), 16) / 255,
    b: parseInt(hex.substring(5, 7), 16) / 255,
  });

  const calculateRelativeLuminance = (rgb) =>
    rgb.map((value) =>
      value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4)
    );

  const luminance = calculateRelativeLuminance(
    Object.values(hexToRgb(surfaceColor))
  ).reduce(
    (acc, value, index) =>
      acc + (index === 0 ? 0.2126 : index === 1 ? 0.7152 : 0.0722) * value,
    0
  );

  return luminance > 0.5 ? "black" : "white";
};
