import { MessageEvents, MessagePayload } from "@CustomTypes/event.type";
import useLogging from "@hooks/logging";
import { createContext, ReactNode, useEffect } from "react";

import store from "@/store/store";

type IMessageListenerProps = {
  children: ReactNode;
};

type Callback = (event: MessageEvent<MessagePayload>) => void;

class Messagenger {
  private origin: string;
  private observers: Record<MessageEvents | string, Set<Callback>> = {};

  constructor(origin = "*") {
    this.origin = origin;
  }

  _emit(event: MessageEvent<MessagePayload>) {
    const eventName = event.data.event;
    this.observers[eventName]?.forEach((callback) => {
      try {
        callback(event);
      } catch (error) {
        console.error(error);
      }
    });
  }

  on(event: MessageEvents, callback: Callback) {
    if (!this.observers[event]) {
      this.observers[event] = new Set();
    }
    if (this.observers[event].has(callback)) return;
    this.observers[event].add(callback);

    return () => this.off(event, callback);
  }

  off(event: MessageEvents, callback: Callback) {
    if (!this.observers[event]) return;
    this.observers[event].delete(callback);
  }

  sendMessage(message: MessagePayload) {
    window.parent.postMessage(message, this.origin);
  }
}

export const MessageContext = createContext<Messagenger | undefined>(undefined);

export default function MessageListeners({ children }: IMessageListenerProps) {
  let origin: string;

  if (window.location.ancestorOrigins) {
    origin = window.location.ancestorOrigins[0];
  } else {
    origin = document.referrer;
  }

  const messenger = new Messagenger(origin);

  const logging = useLogging();
  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  const handleMessage = (event: MessageEvent<MessagePayload>) => {
    logging.debug("Received event from ", event.origin);
    if (event.origin !== origin) return;
    logging.debug("From Parent", event.data);
    messenger._emit(event);
    switch (event.data.event) {
      case "config":
        logging.debug("config", event.data.payload);

        break;
      case "addMeta":
        updateUserMeta(event.data.payload);
        break;
      case "removeMeta":
        removeMeta(event.data.payload.meta as string[]);
        break;
      case "userInfo":
        updateUserInfo(event.data.payload);
        break;
      case "start":
        logging.debug("start", event.data.payload);
        break;
      case "command":
      default:
        console.warn("Unknown event", event.data);
        break;
    }
  };
  const removeMeta = (meta?: string[]) => {
    if (!meta || !Array.isArray(meta)) return;
    store.dispatch.chatModel.removeMetaInfo(meta);
  };
  const updateUserMeta = (meta: Record<string, unknown>) => {
    if (!meta) return;
    store.dispatch.chatModel.addMetaInfo(meta);
  };
  const updateUserInfo = (userInfo: Record<string, unknown>) => {
    if (!userInfo) return;
    store.dispatch.chatModel.updateUserInfo(userInfo);
  };

  return (
    <MessageContext.Provider value={messenger}>
      {children}
    </MessageContext.Provider>
  );
}
